import React, { ReactNode, memo } from 'react'
import { Container } from '@microfrontends/react-components'

type TProps = {
	sectionClass?: string[]
	container?: boolean
	containerClass?: string[]
	title?: string | ReactNode
	children: ReactNode
	ctaLink?: ReactNode
	styles?: React.CSSProperties
}

const GlobalContainer = ({
	container = false,
	containerClass,
	children
}: {
	container?: boolean
	children: React.ReactNode
	containerClass: string[]
}) => {
	if (container) {
		return (
			<Container className={`container ${containerClass.join(' ')}`}>
				{children}
			</Container>
		)
	}
	return <div>{children}</div>
}

const SectionBlock = (props: TProps) => {
	const {
		title,
		sectionClass = [],
		container = true,
		ctaLink = null,
		containerClass = [],
		styles
	} = props

	return (
		<section
			className={`sectionBlock ${sectionClass.join(' ')}`}
			style={styles}
		>
			<GlobalContainer
				containerClass={containerClass}
				container={container}
			>
				<div className='is-flex justify-between align-center section-title'>
					{title && <h2 className='sectionBlock__title'>{title}</h2>}

					{ctaLink && (
						<div className='sectionBlock__link'>{ctaLink}</div>
					)}
				</div>

				<div className='sectionBlock__content'>{props.children}</div>
			</GlobalContainer>
		</section>
	)
}

export default memo(SectionBlock)
