export const news = [
	{
		logo: '/logo/dantri.png',
		title: 'Chiến dịch Empower Growth của VietnamWorks nhận nhiều giải thưởng quốc tế',
		link: 'https://dantri.com.vn/kinh-doanh/chien-dich-empower-growth-cua-vietnamworks-nhan-nhieu-giai-thuong-quoc-te-20231206091511658.htm'
	},
	{
		logo: '/logo/vnexpress.png',
		title: 'VietnamWorks: 64% doanh nghiệp thưởng Tết hơn một tháng lương',
		link: 'https://vnexpress.net/vietnamworks-64-doanh-nghiep-thuong-tet-hon-mot-thang-luong-3859161.html'
	},
	{
		logo: '/logo/cafef.png',
		title: 'Bước chuyển mình sau 20 năm của trang tuyển dụng hàng đầu Việt Nam',
		link: 'https://cafef.vn/cu-hich-chuyen-minh-cua-nen-tang-tuyen-dung-lon-bac-nhat-viet-nam-202210011153134.chn'
	},
	{
		logo: '/logo/cafebiz.png',
		title: 'CEO VietnamWorks: Tập trung vào giá trị cốt lõi để thu hút nhân tài',
		link: 'https://cafebiz.vn/ceo-vietnamworks-tap-trung-vao-gia-tri-cot-loi-de-thu-hut-nhan-tai-176220921142442487.chn'
	},
	{
		logo: '/logo/logo-forbes-big-white.png',
		title: 'Empower Growth: VietnamWorks tái định vị thương hiệu sau 2 thập niên',
		link: 'https://forbes.vn/empower-growth-vietnamworks-tai-dinh-vi-thuong-hieu-sau-2-thap-nien',
		background: 'black'
	},
	{
		logo: '/logo/bothongtintruyenthong.png',
		title: 'VietnamWorks inTECH tổ chức sự kiện về công nghệ AI “The Future of Now”',
		link: 'https://ictvietnam.vn/vietnamworks-intech-to-chuc-su-kien-ve-cong-nghe-ai-the-future-of-now-19466.html'
	},
	{
		logo: '/logo/thoibao.png',
		title: 'VietnamWorks tổ chức Job Fair - ngày hội việc làm lớn nhất năm 2022',
		link: 'https://thoibaonganhang.vn/vietnamworks-to-chuc-job-fair-ngay-hoi-viec-lam-lon-nhat-nam-2022-130593.html'
	},
	{
		logo: '/logo/thanhnien.png',
		title: '‘Hành trình sự nghiệp hạnh phúc’ chạm đến hàng triệu người Việt',
		link: 'https://thanhnien.vn/hanh-trinh-su-nghiep-hanh-phuc-cham-den-hang-trieu-nguoi-viet-1851523227.htm'
	},
	{
		logo: '/logo/tien-phong.png',
		title: 'Hàng nghìn người tham dự sự kiện của Vietnamworks tại TP.HCM',
		link: 'https://tienphong.vn/hang-nghin-nguoi-tham-du-su-kien-cua-vietnamworks-tai-tphcm-post1482550.tpo',
		background: '#a50a02'
	},
	// {
	// 	logo: '/logo/zing-new.png',
	// 	title: 'Job Envision tái định nghĩa bản mô tả công việc truyền thống',
	// 	link: 'https://zingnews.vn/job-envision-tai-dinh-nghia-ban-mo-ta-cong-viec-truyen-thong-post1360627.html'
	// },
	{
		logo: '/logo/laodong.png',
		title: 'VietnamWorks thay đổi nhận diện thương hiệu, công bố sứ mệnh mới',
		link: 'https://laodongtre.laodong.vn/ket-noi/vietnamworks-thay-doi-nhan-dien-thuong-hieu-cong-bo-su-menh-moi-1092939.ldo'
	},
	{
		logo: '/logo/toquoc.png',
		title: 'VietnamWorks: Thiếu hụt lao động vẫn xảy ra ở một số ngành nghề',
		link: 'https://toquoc.vn/vietnamworks-thieu-hut-lao-dong-van-xay-ra-o-mot-so-nganh-nghe-20230116155058415.htm',
		background: 'rgb(151,25,36)'
	},
	{
		logo: '/logo/24h.png',
		title: 'VietnamWorks đồng hành cùng hàng triệu lao động Việt chinh phục “Hành trình sự nghiệp hạnh phúc”',
		link: 'https://www.24h.com.vn/kinh-doanh/vietnamworks-dong-hanh-cung-hang-trieu-lao-dong-viet-chinh-phuc-hanh-trinh-su-nghiep-hanh-phuc-c161a1415605.html',
		background: '#78b43d'
	}
]

export const jobSeekerComment = [
	{
		author: 'Ms. Trúc Năng',
		description:
			'Điều hài lòng của tôi về VietnamWorks là việc thông báo việc làm mới qua email cho người dùng thường xuyên. Điều này giúp cho tôi có cơ hội được tìm hiểu các công việc tuyển dụng phù hợp với kinh nghiệm của mình. Tất nhiên là giao diện của Vietnamworks rất đẹp, dễ sử dụng và tiện lợi giúp tôi có thể tìm kiếm công việc phù hợp một cách nhanh chóng. Mọi thông tin, ngành nghề, khu vực đều được cập nhật rất bài bản. Tôi rất hài lòng về sự trải nghiệm này.'
	},
	{
		author: 'Mr. Quang Tùng',
		description:
			'VietnamWorks có nhiều công việc với những tính chất ngành nghề phong phú. Phản ánh chính xác và đầy đủ nhu cầu tìm việc làm trong xã hội hiện nay và nhu cầu tuyển dụng của các doanh nghiệp. Giao diện trình bày dễ hiểu. Bên cạnh đó, VietnamWorks còn có nhiều nội dung, bài viết phong phú, phản ánh thực tế nhu cầu tuyển dụng, công việc trong giai đoạn hiện nay.'
	},
	{
		author: 'Ms. Thanh Vy',
		description:
			'Khi mình nộp hồ sơ trên trang web, sẽ có mail gửi đến để xác nhận mình đã nộp hồ sơ thành công. Điều này giúp mình biết và quản lý được các vị trí và công ty mình đã ứng tuyển. Bên cạnh đó, giao diện rất dễ sử dụng, mình có thể xem việc làm, thông tin công ty và xem lại các công việc đã xem, đã lưu, hoặc đã ứng tuyển một cách rất dễ dàng. Hơn nữa, các công ty đăng ký đăng tuyển đa số là các công ty uy tín, nên mình rất tin tưởng khi tìm việc trên nền tảng VietnamWorks.'
	},
	{
		author: 'Mr. Hoàng Nhân',
		description:
			'Về công cụ tìm kiếm, tôi đánh giá cao VietnamWorks tìm kiếm theo từ khóa rất chính xác, ngoài ra còn tổng hợp được hiện đang có bao nhiêu công việc liên quan nữa. Còn về thông báo việc làm, phải nói là VietnamWorks gửi thư còn đều đặn hơn cả người yêu tôi nữa (dù tôi chưa có người yêu).'
	},
	{
		author: 'Ms. Thùy Linh',
		description:
			'VietnamWorks là một thương hiệu nổi bật, khi nói đến tìm việc là người ta nghĩ ngay đến VietnamWorks đầu tiên rồi mới đến các nền tảng khác. Ngoài ra, VietnamWorks cũng có nhiều cải tiến, thay đổi để phù hợp với xu thế ngành nghề hiện tại hơn.'
	},
	{
		author: 'Ms. Hoa',
		description:
			'Tính năng lưu lại yêu cầu tìm việc của những lần trước giúp người dùng chỉ cần bấm vào tìm kiếm nhanh chóng, giúp tiết kiệm được thời gian. Bên cạnh đó, việc làm trên VietnamWorks thường là những công việc có yêu cầu cao đi đôi với mức lương tốt.'
	},
	{
		author: 'Ms. Linh Chi',
		description:
			'Tôi nhận thấy hồ sơ trên VietnamWorks được các doanh nghiệp tìm kiếm nhiều và tỷ lệ mời phỏng vấn cũng cao.'
	},
	{
		author: 'Ms. Thu Thảo',
		description:
			'Giao diện web thân thiện, tốc độ thể hiện được thông tin việc làm nhanh, mượt. Đa dạng ngành nghề. Ứng dụng trên di động chạy mượt, không bị thoát ra đột ngột. Những công việc gợi ý phù hợp. Mục HR Insider hữu ích cho người tìm việc.'
	},
	{
		author: 'Ms. Thanh Thảo',
		description:
			'Giao diện website của VietnamWorks rất thân thiện với người dùng, phù hợp cho cả người mới sử dụng lần đầu lẫn người sử dụng lâu năm. Đa dạng thông tin về vị trí làm việc, thông tin giới thiệu về công ty rõ ràng. Những công việc được gợi ý thường dựa trên hành vi của người dùng do đó công việc gợi ý thường rất phù hợp với nhu cầu của người dùng.'
	},
	{
		author: 'Ms. Thùy Dương',
		description:
			'VietnamWorks có đa dạng ngành nghề, các công ty và tổ chức. Hình thức ứng tuyển tiện lợi, người tìm việc có thể xây dựng hồ sơ trực tiếp trên trang VietnamWorks, có thể kết nối với các tài khoản xã hội khác làm tăng tính tương tác và giúp dễ dàng tìm được công việc.'
	},
	{
		author: 'Ms. Thủy Tiên',
		description:
			'Mình rất thích giao diện của Vietnamworks, hữu ích và dễ tìm kiếm. Các công ty, ngành nghề đa dạng và có rất nhiều công việc từ những công ty lớn với mức lương cao như mong đợi. Về việc quản lý hồ sơ, tôi được gợi ý bổ sung chỉnh sửa để hồ sơ của mình hoàn thiện hơn. Ứng dụng VietnamWorks khá hiệu quả và tinh tế, có cái nhìn mới tổng quan và được thay đổi theo sự phát triển của thị trường việc làm. Điều này làm cho tôi khá ấn tượng.'
	},
	{
		author: 'Ms. Thanh Thư',
		description:
			'Thông báo việc làm hằng ngày được gửi đến mail giúp mình dễ theo dõi và ứng tuyển vào vị trí công việc phù hợp. Ngoài ra, trong tất cả các website mình từng dùng thì VietnamWorks đối với mình là trang web tìm việc tốt nhất. Giao diện thân thiện và dễ sử dụng.'
	},
	{
		author: 'Mr. Thanh Lân',
		description:
			'Trong tất cả các công việc tôi đã làm và ngay cả công việc hiện tại, VietnamWorks là nơi giúp tôi có được cơ hội tốt mỗi khi tôi cần.'
	},
	{
		author: 'Mr. Đỗ Đạt',
		description:
			'Tính năng dễ sử dụng, đa số các công việc tuyển trên VietnamWorks khá chất lượng và đạt độ uy tín cao. Các công việc được phân bổ vào các ngành nghề phù hợp, dễ tìm kiếm. Chất lượng của hồ sơ tại Vietnamworks cũng được nâng cao lên khá nhiều. Bên cạnh đó, tôi rất ấn tượng với tính năng cho phép nhà tuyển dụng xem hồ sơ.'
	},
	{
		author: 'Ms. Hoàng Lan',
		description:
			'VietnamWorks là nơi tìm việc uy tín với chất lượng công ty đăng tin tuyển dụng là những công ty lớn, uy tín, tạo lòng tin. Thiết kế giao diện dễ nhìn, chuyên nghiệp. Thao tác tìm việc, lưu hồ sơ, lưu các công việc yêu thích rất dễ dàng, dễ quản lý. Thông tin đăng tải dễ nhìn, dễ tìm kiếm các vấn đề cần thiết.'
	},
	{
		author: 'Mr. Ngọc Hân',
		description:
			'Giao diện thông minh, dễ dàng tiếp cận. Tôi đánh giá cao khả năng cung ứng cho người tìm việc các ngành nghề của những công ty hàng đầu trong nước và quốc tế.'
	},
	{
		author: 'Ms. Thu Thủy',
		description:
			'Giao diện hiển thị dễ dàng tìm kiếm ngành nghề, ứng dụng cập nhật việc làm mỗi ngày và luôn đẩy những tin mới lên đầu. Bên cạnh đó, VietnamWorks cũng thường xuyên tham khảo và hỏi ý kiến khách hàng cũng như người lao động để cải thiện hơn ứng dụng, giúp nhà tuyển dụng và người lao động dễ dàng tìm thấy nhau. Ứng dụng có tính năng cập nhật hồ sơ ứng viên vài tháng một lần giúp hồ sơ luôn được làm mới.'
	},
	{
		author: 'Ms. Thu Nhi',
		description:
			'Khi ứng viên nộp đơn ứng tuyển vào một vị trí nào đó, VietnamWorks sẽ thông báo về cho ứng viên biết trạng thái hồ sơ của ứng viên hiện giờ đang là gì. Ví dụ như Hồ sơ đã gửi tới Nhà tuyển dụng, Nhà tuyển dụng đã xem hồ sơ,… Tôi rất thích tính năng này. Ngoài ra, tôi cũng nhận được phản hồi từ Nhà tuyển dụng khi hồ sơ của mình không phù hợp với vị trí họ đang tìm kiếm.'
	},
	{
		author: 'Ms. Quỳnh Loan',
		description:
			'Khi sử dụng VietnamWorks, tôi có thể xem lại những công việc mình đã ứng tuyển hoặc đã lưu. Và có tính năng nhắc hẹn nộp hồ sơ đối với những công việc đã lưu giúp tôi không bỏ sót bất kì cơ hội nào. Ngoài ra, tôi có thể biết được nhà tuyển dụng nào đã xem hồ sơ của mình. Thật sự rất tiện lợi và hữu ích.'
	},
	{
		author: 'Ms. Thanh Nga',
		description:
			'Cá nhân tôi cảm thấy chất lượng việc làm đăng tải trên VietnamWorks hơn hẳn các website tuyển dụng khác tại Việt Nam mà tôi được biết nên tôi tin dùng VietnamWorks.'
	},
	{
		author: 'Mr. Trung Dũng',
		description:
			'VietnamWorks cập nhật liên tục nhu cầu tuyển dụng của các công ty, luôn tạo ra cơ hội việc làm cho nhiều bạn đang tìm kiếm công việc. Ứng viên có thể tham khảo được nhiều ngành nghề khác nhau và có sự lựa chọn riêng của bản thân. Dễ sử dụng và dễ hiểu cho người dùng. Cảm ơn VietnamWorks.'
	},
	{
		author: 'Mr. Lộc',
		description:
			'VietnamWorks cung cấp thông tin việc làm phù hợp, cập nhật công việc liên tục cũng như thời hạn nộp hồ sơ rõ ràng ở từng công việc. Công cụ tính toán lương hiệu quả và hữu dụng.'
	},
	{
		author: 'Ms. Ái Nhi',
		description:
			'Nhìn chung, các tính năng trên VietnamWorks dễ tương tác. Tôi thích nhất tính năng thông báo về việc nhà tuyển dụng đã xem hồ sơ của mình. Thời đại công nghệ, việc có thông tin kịp thời giúp khách hàng cập nhật tình hình và có những giải pháp kịp thời hơn thực sự rất hữu ích.'
	}
]
