import { Button, ELanguage, Slider } from '@microfrontends/react-components'
import Image from 'next/legacy/image'
import React, { memo, useContext, useEffect, useState } from 'react'
import SectionBlock from '../sectionBlock'
import { news, jobSeekerComment } from './constant'
import {
	IconCareerPath,
	IconNumerology,
	QuotationMarkOpen,
	QuotationMarkClose
} from './images'
import { Banner, Block, Comment, Container, News } from './WowCVSection.css'
import AppContext from '../../context/AppContext'

enum EDevice {
	MOBILE = 'mobile',
	TABLET = 'tablet',
	LAPTOP = 'laptop',
	DESKTOP = 'desktop'
}

const baseImageUrl = process.env.NEXT_PUBLIC_IMAGE_URL

const WowCVSection = () => {
	const { langCode, t } = useContext(AppContext)
	const [device, setDevice] = useState(EDevice.DESKTOP)
	const isMobile = device === EDevice.MOBILE
	const isTablet = device === EDevice.TABLET || device === EDevice.LAPTOP
	const isDesktop = device === EDevice.DESKTOP

	useEffect(() => {
		const handleDeviceChange = () => {
			if (window.innerWidth <= 768) {
				setDevice(EDevice.MOBILE)
			} else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
				setDevice(EDevice.TABLET)
			} else if (window.innerWidth > 1024 && window.innerWidth <= 1440) {
				setDevice(EDevice.LAPTOP)
			} else {
				setDevice(EDevice.DESKTOP)
			}
		}

		handleDeviceChange()
		window.addEventListener('resize', handleDeviceChange)

		return () => {
			window.removeEventListener('resize', handleDeviceChange)
		}
	}, [])

	return (
		<SectionBlock>
			<Container>
				{/* Banner section */}
				{isDesktop && (
					<Banner.Container>
						<Banner.Content style={{ width: 'calc(100% - 600px)' }}>
							<Banner.Title>
								{t('“Wow” your next employers with')}{' '}
								<span>VietnamWorks WowCV</span>
							</Banner.Title>
							<Banner.Text>
								{t(
									'Create yours CV now to win your dream jobs'
								)}
								<br />
								{langCode === ELanguage.EN ? (
									'It’s quick and always free.'
								) : (
									<>
										<strong>&quot;Wow!&quot;</strong> ngay
										khi nhìn thấy CV của bạn.
									</>
								)}
							</Banner.Text>
							<a
								href='/wowcv?utm_source_navi=vnw_homepage&utm_medium_navi=wowcv'
								style={{ display: 'inline-block' }}
							>
								<Button type='primary'>{t('Create CV')}</Button>
							</a>
						</Banner.Content>

						<Banner.Image style={{ width: 1340 }}>
							<Image
								src={`${baseImageUrl}/logo/wowcvfordesktop.png`}
								alt='banner'
								quality={75}
								sizes='100%'
								layout='fill'
							/>
						</Banner.Image>
					</Banner.Container>
				)}
				{isTablet && (
					<Banner.Container>
						<Banner.Content style={{ width: 'calc(100% - 300px)' }}>
							<Banner.Title>
								{t('“Wow” your next employers with')}{' '}
								<span>VietnamWorks WowCV</span>
							</Banner.Title>
							<Banner.Text>
								{t(
									'Create yours CV now to win your dream jobs'
								)}
								<br />
								{langCode === ELanguage.EN ? (
									'It’s quick and always free.'
								) : (
									<>
										<strong>&quot;Wow!&quot;</strong> ngay
										khi nhìn thấy CV của bạn.
									</>
								)}
							</Banner.Text>
							<a href='/wowcv?utm_source_navi=vnw_homepage&utm_medium_navi=wowcv'>
								<Button type='primary'>{t('Create CV')}</Button>
							</a>
						</Banner.Content>

						<Banner.Image style={{ width: 776 }}>
							<Image
								src={`${baseImageUrl}/logo/wowcvfortablet.png`}
								alt='banner'
								sizes='100%'
								layout='fill'
							/>
						</Banner.Image>
					</Banner.Container>
				)}
				{isMobile && (
					<Banner.Mobile>
						<Banner.Content style={{ padding: 0 }}>
							<Banner.Title>
								{t('“Wow” your next employers with')}{' '}
								<span>VietnamWorks WowCV</span>
							</Banner.Title>
							<Banner.Text>
								{t(
									'Create yours CV now to win your dream jobs'
								)}
								<br />
								{langCode === ELanguage.EN ? (
									'It’s quick and always free.'
								) : (
									<>
										<strong>&quot;Wow!&quot;</strong> ngay
										khi nhìn thấy CV của bạn.
									</>
								)}
							</Banner.Text>
							<a href='/wowcv?utm_source_navi=vnw_homepage&utm_medium_navi=wowcv'>
								<Button type='primary'>{t('Create CV')}</Button>
							</a>
						</Banner.Content>

						<Banner.ImageMobile>
							<Image
								src={`${baseImageUrl}/logo/wowcvformobile.png`}
								alt='banner'
								sizes='100%'
								layout='fill'
							/>
						</Banner.ImageMobile>
					</Banner.Mobile>
				)}

				{/* Block section */}
				<Block.Container isMobile={isMobile}>
					<Block.Item isMobile={isMobile}>
						{isMobile && (
							<Block.Right>
								<IconNumerology />
							</Block.Right>
						)}

						<Block.Left>
							<Block.LeftTitle>{t('Numerology')}</Block.LeftTitle>
							<Block.LeftText>
								{t(
									'A reference tool that helps you suggest the right career based on the strengths and weaknesses of the numbers that are right for you. Based on the information: Your name, date of birth, Numerology will analyze and create a complete career chart for you. Review to renew!'
								)}
							</Block.LeftText>
							<Block.LeftLink
								href={`${t(
									'numerology'
								)}?utm_source_navi=vnw_homepage&utm_medium_navi=numerology`}
							>
								{t('View more')}
							</Block.LeftLink>
						</Block.Left>

						{!isMobile && (
							<Block.Right>
								<IconNumerology />
							</Block.Right>
						)}
					</Block.Item>

					<Block.Item isMobile={isMobile}>
						{isMobile && (
							<Block.Right>
								<IconCareerPath />
							</Block.Right>
						)}

						<Block.Left>
							<Block.LeftTitle>
								{t('Career Path')}
							</Block.LeftTitle>
							<Block.LeftText>
								{t(
									"VietnamWorks helps you compare yourself to remove obstacles on the way to promotion. Let's get out of the career maze by consulting the career progression path of all professions!"
								)}
							</Block.LeftText>
							<Block.LeftLink
								href={`${t(
									'career-path'
								)}?utm_source_navi=vnw_homepage&utm_medium_navi=careerpath`}
							>
								{t('View more')}
							</Block.LeftLink>
						</Block.Left>

						{!isMobile && (
							<Block.Right>
								<IconCareerPath />
							</Block.Right>
						)}
					</Block.Item>
				</Block.Container>

				{/* News section */}
				<News.Container>
					<News.Title>{t('News about VietnamWorks')}</News.Title>

					{isMobile ? (
						<Slider id='news-wowcv' slides={{ elementPerView: 1 }}>
							{news.map((item, idx) => (
								<News.LinkWrapper
									href={item.link}
									key={idx}
									target='_blank'
									className='Homepage_News'
								>
									<News.ItemWrapper>
										<News.Item>
											<News.Logo>
												<Image
													alt={item.logo}
													src={`${baseImageUrl}${item.logo}`}
													width={120}
													height={75}
													layout='responsive'
													objectFit='contain'
													style={{
														backgroundColor:
															item.background
																? item.background
																: 'transparent'
													}}
												/>
											</News.Logo>

											<News.Content>
												{item.title}
											</News.Content>
										</News.Item>
									</News.ItemWrapper>
								</News.LinkWrapper>
							))}
						</Slider>
					) : (
						<Slider id='news-wowcv' slides={{ elementPerView: 3 }}>
							{news.map((item, idx) => (
								<News.LinkWrapper
									href={item.link}
									key={idx}
									target='_blank'
								>
									<News.ItemWrapper>
										<News.Item>
											<News.Logo>
												<Image
													alt={item.logo}
													src={`${baseImageUrl}${item.logo}`}
													width={120}
													height={75}
													layout='responsive'
													objectFit='contain'
													style={{
														backgroundColor:
															item.background
																? item.background
																: 'transparent'
													}}
												/>
											</News.Logo>

											<News.Content>
												{item.title}
											</News.Content>
										</News.Item>
									</News.ItemWrapper>
								</News.LinkWrapper>
							))}
						</Slider>
					)}
				</News.Container>

				{/* Jobseeker about Vietnamworks - Ứng viên nói về VietnamWorks */}
				<Comment.Container>
					<Comment.Title>
						{t('Jobseeker about Vietnamworks')}
					</Comment.Title>
					{isMobile ? (
						<Slider id='news-wowcv' slides={{ elementPerView: 1 }}>
							{jobSeekerComment.map((item, idx) => (
								<Comment.Wrapper key={idx}>
									<Comment.ItemWrapper>
										<Comment.Item>
											<Comment.Content>
												<div>
													<QuotationMarkOpen />
												</div>
												{item.description}
												<div>
													<QuotationMarkClose />
												</div>
											</Comment.Content>
											<Comment.Author>
												{item.author}
											</Comment.Author>
										</Comment.Item>
									</Comment.ItemWrapper>
								</Comment.Wrapper>
							))}
						</Slider>
					) : (
						<Slider id='news-wowcv' slides={{ elementPerView: 2 }}>
							{jobSeekerComment.map((item, idx) => (
								<Comment.Wrapper key={idx}>
									<Comment.ItemWrapper>
										<Comment.Item>
											<Comment.Content>
												<div>
													<QuotationMarkOpen />
												</div>
												<Comment.Description>
													{item.description}
												</Comment.Description>
												<div>
													<QuotationMarkClose />
												</div>
											</Comment.Content>
											<Comment.Author>
												{item.author}
											</Comment.Author>
										</Comment.Item>
									</Comment.ItemWrapper>
								</Comment.Wrapper>
							))}
						</Slider>
					)}
				</Comment.Container>
			</Container>
		</SectionBlock>
	)
}

export default memo(WowCVSection)
