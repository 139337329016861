import { Colors } from '@microfrontends/react-components'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Banner = {
	Container: styled.div`
		display: flex;
		align-items: center;

		height: 310px;
		border: 1px solid #ccdeff;
		border-radius: 6px;
		position: relative;
		margin-bottom: 50px;
	`,
	Mobile: styled.div`
		border: 1px solid #ccdeff;
		border-radius: 6px;
		position: relative;
		margin-bottom: 50px;
		padding: 24px 16px 90px 16px;
	`,
	Content: styled.div`
		padding: 0 50px;
	`,
	Title: styled.div`
		font-weight: 700;
		font-size: 36px;
		line-height: 44px;
		letter-spacing: -2px;
		text-transform: capitalize;
		margin-bottom: 8px;

		> span {
			color: ${Colors.primary};
		}
	`,
	Text: styled.div`
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 16px;
	`,
	Image: styled.div`
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 308px;
	`,
	ImageMobile: styled.div`
		pointer-events: none;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 308px;
	`
}

export const Block = {
	Container: styled.div<{ isMobile: boolean }>`
		display: flex;
		gap: 24px;
		margin-bottom: 50px;

		${({ isMobile }) =>
			isMobile &&
			css`
				flex-direction: column;
			`}
	`,
	Item: styled.div<{ isMobile: boolean }>`
		flex: 1;
		background-color: #ebf2ff;
		padding: 24px 32px;
		display: flex;
		gap: 16px;
		border-radius: 6px;

		${({ isMobile }) =>
			isMobile &&
			css`
				flex-direction: column;
			`}
	`,
	Left: styled.div`
		display: flex;
		flex-direction: column;
		gap: 6px;
	`,
	LeftTitle: styled.div`
		font-size: 18px;
		font-weight: 700;
	`,
	LeftText: styled.div``,
	LeftLink: styled.a`
		display: inline;
		text-decoration: none;
		color: ${Colors.primary};
		transition: 0.25s;
		cursor: pointer;

		&:hover {
			color: ${Colors.button};
		}
	`,
	Right: styled.div`
		flex: 0 0 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	`
}

const NewsContent = styled.div`
	flex: 3;
	color: ${Colors.text};
	display: flex;
	align-items: center;
	transition: 0.25s;
`
const NewsItem = styled.div`
	height: 100%;
	padding: 16px;
	gap: 16px;
	display: flex;
	border-radius: 8px;
	border: 1px solid #f4f4f7;
	background-color: white;
	transition: 0.25s;
`
const NewsLink = styled.a`
	&:hover ${NewsItem} {
		border: 1px solid ${Colors.button};
	}
`
export const News = {
	Container: styled.div`
		height: 100%;
		margin-bottom: 50px;
	`,
	Title: styled.div`
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 24px;
	`,
	LinkWrapper: NewsLink,
	ItemWrapper: styled.div`
		height: 100%;
		border-radius: 12px;
		border: 11px solid #f4f4f7;
	`,
	Item: NewsItem,
	Logo: styled.div`
		flex: 1;
	`,
	Content: NewsContent
}

export const Comment = {
	Container: styled.div`
		height: 100%;
	`,
	Title: styled.div`
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 24px;
	`,
	Wrapper: styled.div`
		height: 100%;
	`,
	ItemWrapper: styled.div`
		height: 100%;
		border-radius: 12px;
		border: 11px solid #f4f4f7;
	`,
	Item: styled(NewsItem)`
		gap: 8px;
		border: none;
		flex-direction: column;
		justify-content: space-between;
	`,
	Content: styled(NewsContent)`
		gap: 6px;
		min-height: 126px;
		div:first-child {
			align-self: flex-start;
		}
		div:last-child {
			align-self: flex-end;
		}
	`,
	Description: styled.div``,
	Author: styled.div`
		font-weight: 600;
		line-height: 18px;
		padding: 8px 40px 0;
		font-size: 14px;
		text-align: right;
	`
}
